import { IService } from "./src/types/api";
import { IImageProxy } from "./src/utils/image";
import { IAlgoliaProduct } from "./src/services/algolia/product";

export class PaginationDefaultConfig {
  static PRODUCTS_NUM_COLS = 3;
  static PRODUCTS_NUM_ROWS = 4;
  static PRODUCTS_PAGINATION_SIZE = 7;
  static SERVICES_NUM_COLS = 2;
  static SERVICES_NUM_ROWS = 4;
  static SERVICES_PAGINATION_SIZE = 7;
}

export class ReviewConfig {
  static COUNT_MAX = 20;
  static RATING_MAX = 10;
}

export const ERROR_MESSAGES = {
  UNKNOWN: "An unknown error has occurred, please contact us for more support.",
};

export const IMAGES = {
  TOOLKIT_LOGO: { type: "local", handle: "toolkit-logo-multi.svg" } as IImageProxy,
  PRODUCT_DEFAULT: { type: "local", handle: "product-default.png" } as IImageProxy,
};

export const BUILD_FOLDER = ".next-custom";
export const APP_ENV = process.env.NODE_ENV === "development" ? "development" : "production";
export const IMAGE_URL = APP_ENV === "development" ? "http://localhost:8788" : "";

export const PREFETCH_TIMEOUT = 2000;

export const IGNORED_ERRORS = ["The files you are trying to upload are too large"];

export const CATEGORIES_COUNT_SHOW = 12;
export const SPECIAL_WORDS = ["ikea", "ks", "jx", "obp", "cx", "api", "tv", "au", "nz", "us", "kas"];

export const SERVICE_DISPOSAL_CHARGE: Pick<IService, "id" | "name"> = {
  id: "packagingDisposal",
  name: "Packaging Disposal",
};

export const SERVICE_TRAVEL_CHARGE: Pick<IService, "id" | "name"> = {
  id: "travelCharge",
  name: "Travel Charge",
};

export const PRODUCT_EMPTY: IAlgoliaProduct = {
  id: "",
  name: "",
  brand: "",
  category: "",
  subCategory: "",
  price: 0,
  image: IMAGES.PRODUCT_DEFAULT,
};
